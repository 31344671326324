import { InsoleUsageData } from "../../components/Cards/InsoleUsageData";
import { DateRange } from "../../model/dateRange";

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export const zip = (...rows: any[]) =>
  [...rows[0]].map((_, c) => rows.map((row) => row[c]));

export function isDatesEqual(date1: Date, date2: Date) {
  if (date1 && date2 !== undefined) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
}

export function capitaliseFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function absDifference(arr1: any, arr2: any) {
  const res = [];
  for (let i = 0; i < arr1.length; i++) {
    const el = Math.abs((arr1[i] || 0) - (arr2[i] || 0));
    res[i] = el;
  }
  const high = Math.max(...res);
  return Math.round(high);
}

export function isNullOrUndefined<T>(
  obj: T | null | undefined
): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export function ageFromDate(dateOfBirth: any): number {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export const shouldForwardProp = <CustomProps extends Record<string, unknown>>(
  props: Array<keyof CustomProps>,
  prop: PropertyKey
): boolean => !props.includes(prop as string);

export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export function isEmpty(value: any): boolean {
  return typeof value !== "undefined" &&
    value &&
    value !== "NaN" &&
    value !== "0" &&
    value !== 0 &&
    !Object.keys(value).length
    ? true
    : false;
}

export function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedHours = pluralize(hours, "hr");

  const formattedMinutes = pluralize(minutes, "min");

  return hours
    ? `${formattedHours} ${formattedMinutes}`
    : `${formattedMinutes}`;
}

export const minutesToHours = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes} mins`;
  }

  return `${(minutes / 60).toFixed(1)} hrs`;
};

export function insoleUsageToHours(data: InsoleUsageData) {
  const rawArray = [
    Number(data?.walk),
    Number(data?.sit),
    Number(data?.stand),
    Number(data?.other),
  ];
  const sum = rawArray.reduce((partialSum, a) => partialSum + a, 0);

  return toHoursAndMinutes(sum);
}

export function removeSpacesFromObject(data: any) {
  return data.map((item: any) => {
    var temp = Object.assign({}, item);
    for (var [k, v] of Object.entries(temp)) {
      if (typeof v === "string") {
        temp[k] = v.replace(/\s/g, "");
      }
    }
    return temp;
  });
}

export const celsiusToFahrenheit = (degrees: number) => 1.8 * degrees + 32;

export function toFixed(num: number, precision: number) {
  return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
    precision
  );
}

//dates

export function findAdjacentDate(
  arr: Date[] | undefined,
  targetDate: Date | undefined
): {
  prevDate: Date | null;
  nextDate: Date | null;
  isEarliest: boolean;
  isLatest: boolean;
} {
  if (!arr || !targetDate) {
    return {
      prevDate: null,
      nextDate: null,
      isEarliest: false,
      isLatest: false,
    };
  }

  const targetTime = targetDate.getTime();

  const closestIndex = arr.findIndex((date) => {
    const dateValue = new Date(date).getTime();
    return dateValue >= targetTime;
  });

  if (closestIndex !== -1) {
    const prevDate = closestIndex > 0 ? arr[closestIndex - 1] : null;
    const nextDate =
      closestIndex < arr.length - 1 ? arr[closestIndex + 1] : null;

    const isEarliest =
      new Date(targetDate).getTime() === new Date(arr[0]).getTime();
    const isLatest =
      new Date(targetDate).getTime() ===
      new Date(arr[arr.length - 1]).getTime();

    return { prevDate, nextDate, isEarliest, isLatest };
  } else {
    return {
      prevDate: null,
      nextDate: null,
      isEarliest: false,
      isLatest: false,
    };
  }
}

export function getAdjacentDate(
  givenDate: Date,
  direction: "next" | "previous"
): Date {
  let newDate = new Date(givenDate.getTime()); // Create a new Date object - need to mutate the data to force a render
  let dayChange = direction === "next" ? 1 : -1;
  newDate.setDate(newDate.getDate() + dayChange);
  return newDate;
}

//timezones

export function applyOffsetToTimestamp(
  timeStamp: string,
  utcOffset: string
): string {
  //hack - if we get an offset in the old format, add ":00" to the end - to be removed at some point
  if (!utcOffset.includes(":")) {
    utcOffset = utcOffset + ":00";
  }

  timeStamp = timeStamp + "+0000";
  const rawDate = new Date(Date.parse(timeStamp));

  //calculate offset
  const operator = utcOffset.substring(0, 1);
  let [h, m] = utcOffset.split(":");
  let hours = Number.parseInt(h.substring(utcOffset.indexOf(operator) + 1));
  let minutes = Number.parseInt(m);
  let totalMinutes = hours * 60 + (hours < 0 ? -minutes : minutes);

  switch (operator) {
    case "+":
      const dateAdded = new Date(
        rawDate.getTime() + totalMinutes * 60000
      ).toUTCString();
      return userTimezoneOffsetToTime() === utcOffset
        ? dateAdded.split("GMT")[0]
        : `${dateAdded}${utcOffset}`;
    case "-":
      const dateSubtracted = new Date(
        rawDate.getTime() - totalMinutes * 60000
      ).toUTCString();
      return userTimezoneOffsetToTime() === utcOffset
        ? dateSubtracted.split("GMT")[0]
        : `${dateSubtracted}${utcOffset}`;
    default:
      return rawDate.toUTCString();
  }
}

export function userTimezoneOffsetToTime(): string {
  const userOffset = new Date().getTimezoneOffset().toString();
  let userOperator;
  let userOffsetValue;

  if (userOffset.substring(0, 1) === "-") {
    userOperator = "-";
    userOffsetValue = userOffset;
  } else {
    userOperator = "+";
    userOffsetValue = userOffset.split("-"[1]);
  }

  const minutes = Number(userOffsetValue);

  var m = minutes % 60;
  var h = (minutes - m) / 60;

  return (
    (userOperator === "-" ? "+" : "-") +
    (h < 10 ? "0" : "") +
    Math.abs(h).toString() +
    ":" +
    (m < 10 ? "0" : "") +
    m.toString()
  );
}

export function compareTimezoneToHCP(
  timeStamp: string | undefined,
  hasBeenTravelling: boolean | undefined
): boolean {
  //hack1 - api is returning "N\A" as a timestamp value and it really shouldn't - for now, return false if this is present
  if (timeStamp === "N\\A") return false;

  //hack2 - if we get an offset in the old format, add ":00" to the end - to be removed at some point
  if (timeStamp && !timeStamp.includes(":")) {
    timeStamp = timeStamp + ":00";
  }
  const hcpTimeZone = userTimezoneOffsetToTime();

  return timeStamp !== hcpTimeZone && hasBeenTravelling === false;
}

export const getDateEndByRange = (
  startDate: Date,
  range: "week" | "month" | "year"
): Date => {
  const millisecondsInDay = 86400000;
  let rangeTime;
  switch (range) {
    case "week":
      rangeTime = millisecondsInDay * 7;
      break;
    case "month":
      rangeTime = millisecondsInDay * 30;
      break;
    case "year":
      rangeTime = millisecondsInDay * 365;
  }
  return new Date(new Date(startDate).getTime() - rangeTime);
};

export const maxDate = (dates: Date[]) =>
  new Date(Math.max(...dates.map(Number)));

export function resetTimeforDate(date: Date) {
  if (date !== undefined) {
    //add 1 to month, because Javascript
    const month = date.getMonth() + 1;

    return `${date.getFullYear()}-${(month < 10 ? "0" : "") + month}-${
      (date.getDate() < 10 ? "0" : "") + date.getDate()
    }T00:00:00`;
  }
}

export function getDateRanges(
  startingDate?: Date,
  trimTime: boolean = false,
  monthsToAdd?: number
) {
  const timeElement = "T00:00:00";
  const today = startingDate ? new Date(startingDate) : new Date();
  //add 1 to month, because Javascript
  const startMonth = today.getMonth() + 1;
  const startDate = `${today.getFullYear()}-${
    (startMonth < 10 ? "0" : "") + startMonth
  }-01`;

  if (!trimTime) startDate.concat(timeElement);

  //add 1 to month, because Javascript
  const endMonth = (startMonth % 12) + (monthsToAdd || 0) + 1;
  const endDate = `${
    startMonth === 12 ? today.getFullYear() + 1 : today.getFullYear()
  }-${(endMonth < 10 ? "0" : "") + endMonth}-01`;

  if (!trimTime) endDate.concat(timeElement);

  const result: DateRange = { startDate: startDate, endDate: endDate };

  return result;
}

export function convertDate(date: Date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}

//settings

export function GetSetting(collection: any, name: any) {
  const setting = collection.find((obj: any) => obj.name === name);
  return setting ? JSON.parse(setting.value) : null;
}

//downloads

export const saveJsonAsFile = (filename: any, dataObjToWrite: any) => {
  const obj = JSON.parse(dataObjToWrite);
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(obj, null, 4));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", filename);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

//environment
//usage -  {isDevelopment() && <p>This is a development or SIT environment.</p>}
export const isDevelopment = (): boolean => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  return environment === "DEV" || environment === "SIT";
};

//guids

export const isValidGUID = (guid: string): boolean => {
  const guidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return guidPattern.test(guid);
};
