import { fetch } from "../helpers/api/api";
import {
  ErrorResponse,
  handleResponse,
  ResponseData,
} from "../helpers/api/handleResponse";
import { ComplianceMode } from "../model/compliance/ComplianceMode";
import { PatientHealthcareProfessionalAssignRequest } from "../model/patient/patientHealthcareProfessionalAssignRequest";
import { PatientHealthcareProfessionalRevokeRequest } from "../model/patient/PatientHealthcareProfessionalRevokeRequest";

const baseUrl = `${process.env.REACT_APP_API_URL}/healthcareprofessional`;
const complianceMode = process.env.REACT_APP_COMPLIANCE_MODE as ComplianceMode;

export const healthcareProfessionalService = {
  getPatients,
  getPendingPatients,
  getPatientDetail,
  registerPatient,
  revokePatient,
};

function getPatients(
  accountId: string,
  isAuthorized: boolean,
  showActive?: boolean
) {
  return fetch(
    `${baseUrl}/get-patients/${accountId}/${isAuthorized}/${
      !showActive ? true : false
    }/${complianceMode}`
  )
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getPendingPatients(accountId: string) {
  return fetch(`${baseUrl}/pending-authorizations/${accountId}`)
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getPatientDetail(patientAccountId: string) {
  return fetch(`${baseUrl}/get-patient/${patientAccountId}/`)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function registerPatient(
  hcpAccountId: string,
  isAuthorizedByPatient: boolean,
  patientID: string
) {
  const request: PatientHealthcareProfessionalAssignRequest = {
    healthcareProfessionalAccountGuid: hcpAccountId,
    isAuthorizedByPatient: isAuthorizedByPatient,
    patientAccountGuid: patientID,
  };

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/register-patient`, requestOptions)
    .then((response: ResponseData) => handleResponse(response))
    .catch((error: ErrorResponse) => handleResponse(error));
}

function revokePatient(hcpAccountId: string, patientID: string) {
  const request: PatientHealthcareProfessionalRevokeRequest = {
    healthcareProfessionalAccountGuid: hcpAccountId,
    patientAccountGuid: patientID,
  };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/revoke-patient`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}
