import { CompliancePeriod } from "../../model/compliance/CompliancePeriod";
import { fetch } from "../../helpers/api/api";
import { handleResponse } from "../../helpers/api/handleResponse";
import { ComplianceAutotrackingRequest } from "../../model/compliance/ComplianceAutotrackingRequest";

const baseUrl = `${process.env.REACT_APP_API_URL}/patientcompliance`;

export const complianceService = {
  getOverview,
  getPeriods,
  updatePeriod,
  toggleAutoTracking,
};

function getOverview(patientGuid: string) {
  return fetch(`${baseUrl}/current/${patientGuid}`)
    .then((response: any) => handleResponse(response))
    .then((data: any) => {
      return data;
    })
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getPeriods(patientGuid: string) {
  return fetch(`${baseUrl}/${patientGuid}`)
    .then((response: any) => handleResponse(response))
    .then((data: any) => {
      return data;
    })
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function updatePeriod(period: CompliancePeriod) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(period),
  };
  return fetch(`${baseUrl}`, requestOptions)
    .then((response: any) => handleResponse(response))
    .then((data: any) => {
      return data;
    })
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function toggleAutoTracking(request: ComplianceAutotrackingRequest) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/switch-calculation`, requestOptions)
    .then((response: any) => handleResponse(response))
    .then((data: any) => {
      return data;
    })
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}
