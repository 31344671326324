import * as React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TableHead, Typography } from "@mui/material";
import { useClientTheme } from "../../../helpers/hooks/useClientTheme";
import { Temperature } from "../../../model/temperature";
import AlertCircle from "../../../components/AlertCircle";

const useStyles = makeStyles((theme: any) => ({
  tableCellHeader: {
    "&.MuiTableCell-root": {
      height: "48px",
      textAlign: "center",
      fontWeight: 700,
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {},
  },
  centerContentCell: {
    "&.MuiTableCell-root": {
      textAlign: "center",
    },
  },
  emptyCell: {
    "&.MuiTableCell-root": {
      padding: "0px",
      height: "40px",
    },
  },
  dividerCell: {
    "&.MuiTableCell-root": {
      borderTop: "none",
    },
  },
  headerText: {
    color: "white",
    textAlign: "center",
  },
}));

const TemperatureInterpretationTableComponent = () => {
  const classes = useStyles();
  const { theme } = useClientTheme();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.main",
        borderRadius: "10px",
        padding: "5px",
      }}
    >
      <Typography className={classes.headerText} component="h5" variant="h5">
        Interpretation Table
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "10px",
          mt: "3px",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableCell className={classes.tableCellHeader}>
              <Typography>
                At risk
                <React.Fragment>&nbsp;</React.Fragment>
                <strong>temperature</strong>
                <React.Fragment>&nbsp;</React.Fragment>
                event
              </Typography>
            </TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>
                <React.Fragment>&nbsp;</React.Fragment>
                <Typography align="left">
                  The wearer was advised to check their feet and take action as
                  needed. Potential pre-ulcerative state, the
                  <React.Fragment>&nbsp;</React.Fragment>
                  <strong>wearer is advised to contact their clinician.</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <AlertCircle fill={theme.palette.alertLevel3.main} />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>
                  A<React.Fragment>&nbsp;</React.Fragment>
                  <strong>
                    temperature difference of greater than
                    <React.Fragment>&nbsp;</React.Fragment>
                    {theme.metrics.temperature.unit === Temperature.Celsius
                      ? "2.2°C "
                      : " 4.0°F "}
                  </strong>
                  <React.Fragment>&nbsp;</React.Fragment>
                  was detected between the left and right feet in the same
                  region for at least 30 minutes within a 60 minute period. The
                  alert is triggered when this temperature difference is
                  detected
                  <strong>
                    <React.Fragment>&nbsp;</React.Fragment>
                    on this day and at least twice on the previous day.
                  </strong>
                </Typography>
              </TableCell>
              <TableCell className={classes.emptyCell} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TemperatureInterpretationTableComponent;
