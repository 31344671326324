import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import PatientDetailsHeader from "../../../components/Account/PatientDetailsHeader";
import AlertCircle from "../../../components/AlertCircle";
import ComplianceCard from "../../../components/Cards/ComplianceCard/ComplianceCard";
import DetailCard from "../../../components/Cards/DetailCard";
import Scrollable from "../../../components/Scrollable";
import ControlVisibility from "../../../components/Settings/ControlVisibility";
import DailyMetricsPage from "../../DailyMetricsPage";
import { ActiveType } from "../../../model/activeType";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getActiveOverview } from "../../../redux/actions/activeActions";
import { activeOverview } from "../../../redux/selectors/activeSelectors";
import { selectPatients } from "../../../redux/selectors/patientSelectors";
import { Patient } from "../../../model/patient/patient";
import {
  clearComplianceData,
  getComplianceOverview,
} from "../../../redux/actions/complianceActions";
import { complianceOverview } from "../../../redux/selectors/complianceSelectors";
import { useClientTheme } from "../../../helpers/hooks/useClientTheme";
import { getPatientDetail } from "../../../redux/actions/healthcareProfessionalActions";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroContent: {
    marginTop: 30,
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
}));

const PatientDetail: React.FC = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const { theme } = useClientTheme();
  const classes = useStyles();
  const activeOverviewData = useSelector(activeOverview);
  const complianceOverviewData = useSelector(complianceOverview);
  const patients = useSelector(selectPatients);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();

  //this is dupliacted in the compliance table page - refactor

  function renderComplianceTooltip(fill?: string) {
    const complianceCircles = [
      {
        fill: theme.palette.complianceNotAchievable.main,
        text: "16 of 30 days cannot or have not been achieved",
      },
      {
        fill: theme.palette.complianceAtRisk.main,
        text: "At risk of not completing 16 of 30 days",
      },
      {
        fill: theme.palette.complianceOnTrack.main,
        text: "On track to complete 16 of 30 days",
      },
      {
        fill: theme.palette.complianceCompleted.main,
        text: "Completed more than 16 of 30 days",
      },
    ];

    if (fill) {
      const circle = complianceCircles.find((circle) => circle.fill === fill);
      return circle ? circle.text : "";
    }

    return (
      <Grid container>
        <Grid item xs={12} mt={0.5}>
          Compliance as of today for the current and past 30 days periods.
        </Grid>
        {complianceCircles.map((circle, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <AlertCircle fill={circle.fill} />
            </Grid>
            <Grid item xs={7} style={{ margin: "5px 0" }}>
              {circle.text}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  useEffect(() => {
    if (patientId) {
      dispatch(getPatientDetail(patientId));
      dispatch(getActiveOverview(patientId));
      dispatch(getComplianceOverview(patientId));
    }
    //clearComplianceData
    const selectedPatient = patients.find((p) => p.accountGuid === patientId);
    setSelectedPatient(selectedPatient);

    return () => {
      dispatch(clearComplianceData());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const handleClick = (e: any) => {
    navigate(`/dashboard/patients/${patientId}/active/${e}`);
  };

  const handleComplianceClick = () => {
    navigate(`/dashboard/patients/${patientId}/other/compliance`);
  };

  return (
    <div className={classes.root}>
      <Scrollable>
        <PatientDetailsHeader selectedPatient={selectedPatient} />

        <DailyMetricsPage selectedPatientId={patientId} />

        <>
          <div className={classes.heroContent}>
            <Typography variant="h5" align="left" gutterBottom>
              <strong>Other</strong>
            </Typography>
          </div>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <ControlVisibility value="latestCustom">
              {activeOverviewData?.latestCustom ? (
                <Grid item>
                  <DetailCard
                    title={ActiveType["_CTEST"]}
                    icon="_TUGT"
                    onClick={() => handleClick("_CTEST")}
                    popoverContent="Access the raw files of the custom test recordings."
                    content="Click here to access the raw files of the custom test recordings"
                  />
                </Grid>
              ) : null}
            </ControlVisibility>
            <ControlVisibility value="latest10MWT">
              {activeOverviewData?.latest10MWT ? (
                <Grid item>
                  <DetailCard
                    title={ActiveType["_10MWT"]}
                    icon="_10MWT"
                    onClick={() => handleClick("_10MWT")}
                    popoverContent="The 10-Metre Walk Test is a validated performance measure used to assess walking speed in metres per second over a short distance. It can be employed to determine functional mobility, gait, and vestibular function."
                    content={activeOverviewData.latest10MWT}
                  />
                </Grid>
              ) : null}
            </ControlVisibility>
            <ControlVisibility value="latestTUG">
              {activeOverviewData?.latestTUG ? (
                <Grid item>
                  <DetailCard
                    title={ActiveType["_TUG"]}
                    icon="_TUGT"
                    onClick={() => handleClick("_TUG")}
                    popoverContent="Timed Up and Go (TUG) Test is a validated test to understand a person's mobility and falls risk. A greater TUG score indicates reduced mobility and balance."
                    content={activeOverviewData?.latestTUG}
                  />
                </Grid>
              ) : null}
            </ControlVisibility>
            <ControlVisibility value="latestCompliance">
              <Grid item>
                <ComplianceCard
                  large
                  title="Compliance"
                  onClick={() => handleComplianceClick()}
                  data={complianceOverviewData}
                  popoverContent={renderComplianceTooltip()}
                />
              </Grid>
            </ControlVisibility>
          </Grid>
        </>
      </Scrollable>
    </div>
  );
};

export default PatientDetail;
