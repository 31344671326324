import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  Grid,
  TableHead,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import AlertCircle from "../../../../components/AlertCircle";
import NoData from "../../../../components/NoData";
import { CompliancePeriod } from "../../../../model/compliance/CompliancePeriod";
import { getComparator, Order, StyledTableCell } from "../../TableComponents";
import { compliancePeriods } from "../../../../redux/selectors/complianceSelectors";
import { calculateComplianceFill } from "../../../../helpers/calculateFill/calculateFill";
import { useClientTheme } from "../../../../helpers/hooks/useClientTheme";
import { updatePatientCompliancePeriod } from "../../../../redux/actions/complianceActions";
import { useAppThunkDispatch } from "../../../../redux/configureStore";

interface HeadCell {
  id: keyof CompliancePeriod;
  label: string;
  numeric: boolean;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  toolTip?: string;
}

interface ComplianceDetailTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CompliancePeriod
  ) => void;
  order: Order;
  orderBy: string;
}

function formatDateRange(startTime: string, endTime: string): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const startDate = new Date(startTime).toLocaleDateString("en-US", options);
  const endDate = new Date(endTime).toLocaleDateString("en-US", options);
  return `${startDate} - ${endDate}`;
}

function ComplianceDetailTableHead(props: ComplianceDetailTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof CompliancePeriod) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "startTime",
      numeric: false,
      label: "Period",
      align: "left",
    },

    {
      id: "daysOfUse",
      numeric: true,
      label: "Days of use",
      align: "center",
    },
    {
      id: "isBilled",
      numeric: false,
      label: "Was the period billed?",
      align: "center",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <Tooltip title={headCell.toolTip || ""}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const ComplianceDetailTableComponent: React.VoidFunctionComponent = () => {
  const data = useSelector(compliancePeriods);
  const { theme } = useClientTheme();
  const dispatch = useAppThunkDispatch();
  //Not sure if this needs to be put into config?
  const daysOfUseEnd = 30;
  const requiredDaysOfUse = 16;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof CompliancePeriod>("isCurrent");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CompliancePeriod
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCheckboxChange = (id: number, newValue: boolean) => {
    const updatedItem = data.find((item: CompliancePeriod) => item.id === id);
    if (updatedItem) {
      const updatedItemWithNewValue = {
        ...updatedItem,
        isBilled: newValue,
      };
      dispatch(updatePatientCompliancePeriod(updatedItemWithNewValue));
    }
  };

  function getBillingCheckboxProps(period: CompliancePeriod): {
    isDisabled: boolean;
    tooltipTitle: string;
  } {
    const isDisabled =
      period.isCurrent || period.daysOfUse <= requiredDaysOfUse - 1;
    const tooltipTitle = period.isCurrent
      ? "Disabled because it is the current period"
      : period.daysOfUse <= requiredDaysOfUse - 1
      ? "Disabled because days of use is less than the required 16 days"
      : "";

    return { isDisabled, tooltipTitle };
  }

  function renderComplianceTooltip(fill?: string) {
    const complianceCircles = [
      {
        fill: theme.palette.complianceNotAchievable.main,
        text: "16 of 30 days cannot or have not been achieved",
      },
      {
        fill: theme.palette.complianceAtRisk.main,
        text: "At risk of not completing 16 of 30 days",
      },
      {
        fill: theme.palette.complianceOnTrack.main,
        text: "On track to complete 16 of 30 days",
      },
      {
        fill: theme.palette.complianceCompleted.main,
        text: "Completed more than 16 of 30 days",
      },
    ];

    if (fill) {
      const circle = complianceCircles.find((circle) => circle.fill === fill);
      return circle ? circle.text : "";
    }

    return (
      <Grid container>
        <Grid item xs={12} mt={0.5}>
          Compliance as of today for the current and past 30 days periods.
        </Grid>
        {complianceCircles.map((circle, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <AlertCircle fill={circle.fill} />
            </Grid>
            <Grid item xs={7} style={{ margin: "5px 0" }}>
              {circle.text}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  return data.length ? (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="compliance-data-table">
            <ComplianceDetailTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data
                .slice()
                .sort(getComparator(order, orderBy))
                .map((period: CompliancePeriod) => {
                  const { isDisabled, tooltipTitle } =
                    getBillingCheckboxProps(period);
                  return (
                    <TableRow tabIndex={-1} key={period.id}>
                      <StyledTableCell align="left">
                        {formatDateRange(period.startTime, period.endTime)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "300px" }} align="left">
                        <Grid container>
                          <Grid
                            item
                            xs={3}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {!period.isCurrent ? (
                              <Tooltip
                                title={renderComplianceTooltip(
                                  calculateComplianceFill(
                                    theme,
                                    period.daysOfUse,
                                    daysOfUseEnd
                                  )
                                )}
                              >
                                <AlertCircle
                                  data-testid="compliance-circle"
                                  fill={calculateComplianceFill(
                                    theme,
                                    period.daysOfUse,
                                    daysOfUseEnd
                                  )}
                                />
                              </Tooltip>
                            ) : null}
                          </Grid>

                          <Grid item xs={7}>
                            {`${period.daysOfUse} of ${daysOfUseEnd}`}
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title={tooltipTitle}>
                          <span>
                            <Checkbox
                              color="primary"
                              checked={period.isBilled}
                              disabled={isDisabled}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  period.id,
                                  e.target.checked
                                )
                              }
                              data-testid={`checkbox-isbilled-${period.id}`}
                            />
                          </span>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  ) : (
    <NoData message="There is no compliance data for this patient" />
  );
};

export default ComplianceDetailTableComponent;
