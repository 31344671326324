import {
  FETCH_DAILY_METRIC_DATES,
  FETCH_DAILY_METRIC_DATES_SUCCESS,
  FETCH_DAILY_METRIC_DATES_FAILURE,
  FETCH_DAILY_METRICS,
  FETCH_DAILY_METRICS_SUCCESS,
  FETCH_DAILY_METRICS_FAILURE,
  CLEAR_SELECTED_DAILY_METRICS,
  SELECT_DAILY_METRIC_DATE,
} from "../constants";
import { initialState } from "../system/SystemState";
import { DailyMetricsState } from "../../model/dailyMetricsState";
import { DailyMetricsActionTypes } from "../types/dailyMetricsActionTypes";

export default function dailyMetricsReducer(
  state = initialState.dailyMetrics,
  action: DailyMetricsActionTypes
): DailyMetricsState {
  switch (action.type) {
    case FETCH_DAILY_METRIC_DATES:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DAILY_METRIC_DATES_SUCCESS:
      return {
        ...state,
        dates: action.payload,
        isLoading: false,
      };
    case FETCH_DAILY_METRIC_DATES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SELECT_DAILY_METRIC_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case FETCH_DAILY_METRICS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DAILY_METRICS_SUCCESS:
      return {
        ...state,
        selectedDailyMetrics: action.payload,
        isLoading: false,
      };
    case FETCH_DAILY_METRICS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_SELECTED_DAILY_METRICS:
      return {
        ...state,
        // can't expand out an empty dates array here - as it messes up the selectable dates in the date picker
        selectedDate: initialState.dailyMetrics.selectedDate,
        selectedDailyMetrics: initialState.dailyMetrics.selectedDailyMetrics,
      };
    default:
      return state;
  }
}
