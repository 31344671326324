import React, { useEffect, useState } from "react";
//The below import is important for any component that is using chart.js, but the downside is it imports everything from the lib - need to work out a just importing the bits that are needed
import "chart.js/auto";
import "chartjs-plugin-datalabels";

import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { ParameterCard } from "../documented/ParameterCard";
import { CalculatedPassiveParameterModel } from "../../model/passive/calculatedPassiveParameterModel";
import { InsoleUsageData } from "./InsoleUsageData";
import { getDailyParameters } from "./DailyParameters/cardFunctions";
import { PassiveParameterTypeEnum } from "../../model/passive/enum/passiveParameterTypeEnum";
import {
  insoleUsageToHours,
  isEmpty,
  minutesToHours,
} from "../../helpers/utilityFunctions/utilityFunctions";

import { ReactComponent as SittingMan } from "../../assets/icons/sittingMan.svg";
import { ReactComponent as WalkingMan } from "../../assets/icons/walkingMan.svg";
import { ReactComponent as StandingMan } from "../../assets/icons/standingMan.svg";
import { ReactComponent as JumpingMan } from "../../assets/icons/jumpingMan.svg";

interface IDailyParametersCardProps {
  onClick: (arg: string) => void;
  title: string;
  popoverContent: string;
  data?: CalculatedPassiveParameterModel[];
  large?: boolean;
}

const ParamContentContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  width: "100%",
  height: "100%",
  padding: "10px 35px 0",
  boxSizing: "border-box",
}));

const ActivityTrackerCard: React.FC<IDailyParametersCardProps> = ({
  onClick,
  title,
  popoverContent,
  data,
  large,
}: IDailyParametersCardProps) => {
  const [activityTrackerCardData, setActivityTrackerCardData] =
    useState<InsoleUsageData>();

  useEffect(() => {
    if (data) {
      setActivityTrackerCardData(
        getDailyParameters(
          data!,
          PassiveParameterTypeEnum.InsoleUsage
        ) as InsoleUsageData
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const values = [
    Number(activityTrackerCardData?.other),
    Number(activityTrackerCardData?.stand),
    Number(activityTrackerCardData?.sit),
    Number(activityTrackerCardData?.walk),
  ];

  const colors = ["#b3b3b3", "#cce4f4", "#68d1df", "#3f838a"];

  const legendData = [
    {
      component: <WalkingMan />,
      label: "Walk",
    },
    {
      component: <SittingMan />,
      label: "Sit",
    },
    {
      component: <StandingMan />,
      label: "Stand",
    },
    {
      component: <JumpingMan />,
      label: "Other",
    },
  ];

  const pieData = {
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: colors,
        border: 1,
      },
    ],
  };

  const pieOptions: any = {
    responsive: true,
    layout: {
      padding: 80,
    },
    plugins: {
      datalabels: {
        textAlign: "center",
        align: "end",
        anchor: "end",
        color: "#101820",
        font: {
          family: ['"Montserrat"', "Open Sans"].join(","),
          size: 17,
        },
        offset: 3,
        padding: 2,
        formatter: (val: number, context: any) => {
          const displayArray = [false, false, false, false];

          const total = values.reduce((acc, val) => acc + val, 0);

          for (let i = 0; i < values.length; i++) {
            if (values[i] > (total / 100) * 10) {
              displayArray[i] = true;
            }
          }

          const typeOfActionByOrder = [
            "other",
            "standing",
            "sitting",
            "walking",
          ][context.dataIndex];
          return val > 0 && displayArray[context.dataIndex]
            ? minutesToHours(val) + `\n ${typeOfActionByOrder}`
            : "";
        },
      },
    },
  };

  const hoursUsage = activityTrackerCardData
    ? insoleUsageToHours(activityTrackerCardData).split(" ")
    : ["0"];

  const renderLegend = (
    data: Array<{ component: React.ReactNode; label: string }>
  ) => {
    return data.map(({ component, label }, index) => {
      return (
        <Typography
          key={label}
          sx={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
          {component} {label}
        </Typography>
      );
    });
  };

  return (
    <ParameterCard
      large={large}
      title={title}
      iconName="ActivityTracker"
      popoverContent={popoverContent}
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(activityTrackerCardData)}
    >
      <ParamContentContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <Typography fontSize={14}>
            {data && data[0] && data[0].date
              ? new Date(data[0].date).toLocaleDateString()
              : "No Date Specified"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ margin: "-20px 5px 0 0 ", fontWeight: 700 }}
            fontSize={30}
          >
            {hoursUsage[0]}
          </Typography>
          <Typography fontSize={12} fontWeight={700}>
            {hoursUsage.slice(1).join(" ")} using the insoles
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box>{renderLegend(legendData)}</Box>
          <Box
            sx={{
              height: "500px",
              width: "500px",
              marginTop: "-65px",
              marginLeft: "50px",
            }}
          >
            <Chart
              type="pie"
              data={pieData}
              options={pieOptions}
              plugins={[ChartDataLabels]}
            />
          </Box>
        </Box>
      </ParamContentContainer>
    </ParameterCard>
  );
};

export default ActivityTrackerCard;
