import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import PatientDetailsHeader from "../../../components/Account/PatientDetailsHeader";
import BackButton from "../../../components/BackButton";
import ComplianceDetailTableComponent from "./ComplianceDetailTable";
import { Patient } from "../../../model/patient/patient";
import {
  getCompliancePeriods,
  togglePatientComplianceAutotracking,
} from "../../../redux/actions/complianceActions";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { selectedPatientDetail } from "../../../redux/selectors/patientSelectors";
import { getPatientDetail } from "../../../redux/actions/healthcareProfessionalActions";

const ComplianceDetail: React.FC = () => {
  const { patientId } = useParams();
  const patientDetails = useSelector(selectedPatientDetail);
  const [selectedPatient] = useState<Patient>();
  const dispatch = useAppThunkDispatch();

  const toggleSetAutotrack = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      togglePatientComplianceAutotracking(patientId!, event.target.checked)
    );
  };

  useEffect(() => {
    if (patientId) {
      dispatch(getCompliancePeriods(patientId));
      if (!patientDetails) {
        dispatch(getPatientDetail(patientId));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <PatientDetailsHeader selectedPatient={selectedPatient} />
      <BackButton />

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 1, pr: 2 }}
      >
        <Grid item>
          <Typography variant="h5">
            <strong>Compliance</strong>
          </Typography>
        </Grid>
        <Grid item>
          {patientDetails ? (
            <Tooltip title="If auto-tracking is enabled, compliance periods will be automatically generated every 30 days based on the last recorded start date.">
              <FormControlLabel
                control={
                  <Switch
                    data-testid="switch-auto-tracking"
                    checked={patientDetails.isComplianceEnabled}
                    onChange={toggleSetAutotrack}
                    color="primary"
                  />
                }
                label="Auto Tracking"
                labelPlacement="end"
              />
            </Tooltip>
          ) : (
            <Typography sx={{ mr: 2 }}>
              Cannot configure Auto Tracking
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        mt={1}
        spacing={2}
        alignItems="stretch"
      >
        <Grid item mr={4}>
          <ComplianceDetailTableComponent />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComplianceDetail;
